import PropTypes from 'prop-types'
import React from 'react'

export const Header = ({title}) => title
  ? <div className='oglasi-header'>
    <p>{title}</p>
  </div>
  : null

Header.propTypes = {
  title: PropTypes.string
}

Header.defaultProps = {
  title: 'OGLASI - IZBOR IZ PONUDE'
}
