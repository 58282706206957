import PropTypes from 'prop-types'
import React from 'react'
import { adsListViews } from 'Core/constants'

export const GridView = ({onClick, style}) => (
  <li onClick={onClick} className={style === adsListViews.TABLE ? 'active' : ''}>
    <span className='oglasi-filter-grid_item'><i className='ico-filter-prikaz-grid-tekst ico' /></span>
    <div className='tooltip'>Slikovni prikaz</div>
  </li>
)

GridView.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.oneOf([
    adsListViews.LIST,
    adsListViews.TABLE
  ])
}

GridView.defaultProps = {
  onClick: () => {}
}
