import PropTypes from 'prop-types'
import React from 'react'
import { adsListViews } from 'Core/constants'

export const CardView = ({onClick, style}) => (
  <li onClick={onClick} className={style === adsListViews.LIST ? 'active' : ''}>
    <span className='oglasi-filter-grid_item'><i className='ico-filter-prikaz-lista-ikonice ico' /></span>
    <div className='tooltip'>Tabelarni prikaz</div>
  </li>
)

CardView.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.oneOf([
    adsListViews.LIST,
    adsListViews.TABLE
  ])
}
